  <template>
  <div class="pt-5">
    <h1 class="text-center">Customer Page List</h1>

    <v-card class="mx-5 mt-5">
      <v-card-title class="d-flex justify-space-between">
        Customers
        <v-btn color="primary" dark class="mb-2" @click="openAddCustomer()"
          >Add Customer</v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :headers="headers" :items="customers.data">
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.id }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.firstName }}</td>
            <td>{{ item.lastName }}</td>
            <td>{{ item.gender }}</td>
            <td>{{ item.phoneNumber }}</td>
            <td>{{ item.created_at | moment("MM-DD-YYYY hh:mm a") }}</td>
            <td>
              <v-icon
                small
                class="mx-1 green--text"
                @click="openEditCustomer(item)"
                >mdi-pencil</v-icon
              >

              <v-icon small class="red--text" @click="deleteItem(item)"
                >mdi-delete</v-icon
              >
            </td>
          </tr>
          <v-dialog v-model="deleteDialog" max-width="500px">
            <v-card>
              <v-card-text>Do you want to delete this item?</v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn color="error" @click="deleteItemConfirmed">Yes</v-btn>
                <v-btn color="primary" @click="closeDeleteDialog">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="addEditdialog" max-width="500px">
      <v-card>
        <v-card-title v-if="actionDialog == 'add'">Add Customer</v-card-title>
        <v-card-title v-if="actionDialog == 'edit'">Edit Customer</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="submit" ref="customerForm">
            <v-text-field
              outlined
              label="First Name"
              hide-details
              class="mb-3"
              :error-messages="errorMessages.firstName"
              v-model="itemForm.firstName"
            ></v-text-field>
            <v-text-field
              outlined
              label="Last Name"
              hide-details
              class="mb-3"
              v-model="itemForm.lastName"
            ></v-text-field>
            <v-select
              outlined
              label="Gender"
              hide-details
              class="mb-3"
              :items="genders"
              v-model="itemForm.gender"
            ></v-select>
            <v-text-field
              outlined
              label="Phone Number"
              hide-details
              class="mb-3"
              v-model="itemForm.phoneNumber"
            ></v-text-field>
            <v-text-field
              outlined
              label="Email Address"
              hide-details
              class="mb-3"
              v-model="itemForm.email"
            ></v-text-field>
            <v-text-field
              outlined
              label="Password"
              hide-details
              v-if="actionDialog == 'add'"
              v-model="itemForm.password"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-text>
          <v-alert
            v-if="Object.keys(errorMessages.errors).length > 0"
            type="error"
            outlined
          >
            <ul>
              <li v-for="(errors, field) in errorMessages.errors" :key="field">
                <strong>{{ errors[0] }}</strong>
              </li>
            </ul>
          </v-alert>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn color="secondary" @click="closeDialog()">Cancel</v-btn>
          <v-btn color="primary" @click="saveCustomer()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-text class="pt-5"
          >Do you want to delete this customer?</v-card-text
        >
        <v-card-actions class="d-flex justify-end">
          <v-btn color="error" @click="deleteItemConfirmed">Yes</v-btn>
          <v-btn color="primary" @click="closeDeleteDialog">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["customers"]),
  data: () => ({
    deleteDialog: false,
    itemToDelete: null,
    genders: ["Male", "Female"],
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "First Name",
        align: "start",
        sortable: false,
        value: "firstName",
      },

      {
        text: "Last Name",
        align: "start",
        sortable: false,
        value: "lastName",
      },
      { text: "Email-Address", value: "email", sortable: false },
      { text: "Gender", value: "gender", sortable: false },
      { text: "Phone-Number", value: "phone", sortable: false },
      { text: "Added Date", value: "created_at", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    items: [],
    itemForm: {
      id: null,
      firstName: "",
      lastName: "",
      gender: "",
      phoneNumber: "",
      email: "",
      password: "corado123",
    },
    addEditdialog: false,
    actionDialog: "",
    errorMessages: { errors: [] },
  }),
  methods: {
    openAddCustomer() {
      this.actionDialog = "add";
      this.addEditdialog = true;
    },
    openEditCustomer(customer) {
      this.errorMessages = { errors: [] };

      this.actionDialog = "edit";
      this.addEditdialog = true;
      this.itemForm.id = customer.id;
      this.itemForm.firstName = customer.firstName;

      this.itemForm.lastName = customer.lastName;
      this.itemForm.gender = customer.gender;
      this.itemForm.phoneNumber = customer.phoneNumber;
      this.itemForm.email = customer.email;
      
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItemConfirmed() {
      // Use $store.dispatch to delete customer
      this.$store.dispatch("deleteCustomer", this.itemToDelete.id).then(() => {
        this.$store.dispatch("fetchCustomers");
        this.deleteDialog = false;
      });
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    saveCustomer() {
      const dispatchAction =
        this.actionDialog === "add" ? "createCustomer" : "updateCustomer";

      // Use $store.dispatch to create or update customer
      this.$store
        .dispatch(dispatchAction, this.itemForm)
        .then(() => {
          // Fetch customers after creating or updating
          this.$store.dispatch("fetchCustomers");
          this.addEditdialog = false;
          // Reset form fields
          this.itemForm.firstName = "";

          this.itemForm.lastName = "";
          this.itemForm.gender = "";
          this.itemForm.phoneNumber = "";
          this.itemForm.email = "";
          this.itemForm.password = "";

        })
        .catch((err) => {
          this.errorMessages = err.response.data;
        });
    },

    closeDialog() {
      this.addEditdialog = false;
    },
  },
  mounted() {

    this.$store.dispatch("fetchCustomers");
  },
};
</script>

<template>
  <div>
    <v-calendar
      v-model="selectedDates"
      :type="type"
      :events="events"
      @click:event="showEvent"
      color="primary"
      ref="calendar"
      id="calendar"
    >
      <template v-slot:event="{ event }">
        <v-card :color="cardColor(event)" dark class="fill-height pl-2 d-flex">
          <v-icon>{{ icon(event) }}</v-icon>
          <div class="pl-2">
            <b> {{ paymentStatusText(event) }}{{ event.name }} </b>
            <br />
            <b> Name: {{ event.data.customer.lastName}}, {{ event.data.customer.firstName }} </b>

            <br />
            <b>Time: {{ formatTimeRange(event) }}</b>
          </div>
        </v-card>
      </template>
    </v-calendar>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: ["type", "bookings"],
  data() {
    return {
      selectedDates: new Date(),
      events: [],
      currentDate: new Date(),
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  mounted() {
    this.loadBookings();
  },
  methods: {
    cardColor(event) {
      console.log(event.data.status);
      if (event.data && event.data.status === "canceled"  || event.data.status == 'no-show') {
        return "red"; // Color for cancelled booking
      }
      if (event.data && event.data.status === "new") {
        return "blue"; // Color for cancelled booking
      }
      if (event.data && event.data.status === "confirmed") {
        return "orange"; // Color for cancelled booking
      }
      return (
        {
          completed: "green",
          cancelled: "red",
        }[event.payment_status] || event.color
      );
    },
    icon(event) {
      if (event.data && event.data.status === "canceled") {
        return "mdi-close"; // Icon for cancelled booking
      }
      if (event.data && event.data.status === "new") {
        return "mdi-thumbs-up"; // Icon for cancelled booking
      }
      if (event.data && event.data.status === "confirmed") {
        return "mdi-clock"; // Icon for cancelled booking
      }
      if (event.data && event.data.status === "no-show") {
        return "mdi-comment-question-outline"; // Icon for cancelled booking
      }
      return (
        {
          completed: "mdi-cash-check",
          cancelled: "mdi-times",
        }[event.payment_status] || "mdi-thumb-up-outline"
      );
    },
    paymentStatusText(event) {
      let statusText = "";
      if (event.payment_status === "completed") {
        statusText = "(PAID) ";
      }
      if (event.data.status === "confirmed") {
        statusText = "(Confirmed) ";
      }
      if (event.data.status === "new") {
        statusText = "(New) ";
      }
      if (event.data.status === "no-show") {
        statusText = "(No Show) ";
      }
      if (event.data && event.data.status === "canceled") {
        statusText = "(Cancelled) ";
      }
      return statusText;
    },
    formatTimeRange(event) {
      return `${moment(event.start).format("hh:mm")}-${moment(event.end).format(
        "hh:mm"
      )}`;
    },
    showEvent({ nativeEvent, event }) {
      this.$emit("openAppointmentDetails", event);
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        // open()
      }

      nativeEvent.stopPropagation();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    loadBookings() {
      this.bookings.forEach((booking) => {
        const startTime = moment(booking.start_datetime);
        const endTime = moment(booking.end_datetime);
        this.events.push({
          name: booking.service.service_name,
          color: booking.service.color,
          start: startTime.toDate(),
          end: endTime.toDate(),
          timed: true,
          data: booking,
          payment_status: booking.payment.status,
        });
      });
    },
  },
};
</script>

<style >
#calendar .v-event {
  height: auto !important;
  width: 100% !important;
}
</style>
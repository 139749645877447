import axios from "../../plugins/axios";

// state
export const state = () => ({
  businessSettings: null,
});

// getters
export const getters = {
  businessSettingsData: (state) => state.businessSettings,
};

// actions
export const actions = {
  async fetchBusinessSettings({ commit }) {
    return await axios
      .get("/api/business-settings")
      .then((response) => {
        commit("SET_BUSINESS_SETTINGS", response.data);
      })
      .catch((error) => {
        console.error("Error fetching business settings:", error);
      });
  },

  async updateBusinessSettings({ commit }, businessSettings) {
    return await axios
      .post("/api/business-settings", businessSettings)
      .then((response) => {
        commit("SET_BUSINESS_SETTINGS", response.data);
      })
      .catch((error) => {
        console.error("Error updating business settings:", error);
      });
  },
};

// mutations
export const mutations = {
  SET_BUSINESS_SETTINGS(state, businessSettings) {
    state.businessSettings = businessSettings;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="4">
        <v-card class="service-card mb-5 justify-space-between">
          <v-card-title> <h4>Booking Details</h4></v-card-title>
          <v-divider></v-divider>
          <!-- <v-card-subtitle class="subtitle-1"
            >Category: {{ service.category }}</v-card-subtitle
          > -->
          <v-card-text>
            <p style="font-size: 1.5rem" class="px-4">
              Service Name:
              <b> {{ customerBooking.selectedService.service_name }}</b>
              <br />
              Price: <b>P{{ customerBooking.selectedService.price }}</b>
            </p></v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="12" md="12" lg="8">
        <v-card>
          <v-card-title>
            <h4>Schedule Your Appointment Date and Time</h4></v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <!-- Booking Form -->
            <v-form @submit.prevent="submitBookingAndNext">
              <!-- Booking Details -->
              <v-row>
                <v-col md="6" sm="12">
                  <v-card class="pb-2" id="datepicker_1">
                    <v-date-picker
                      v-model="bookingDate"
                      label="Booking Date"
                      required
                      full-width
                      color="primary"
                      :min="minDate"
                      @change="bookingDateChanged"
                    ></v-date-picker>
                  </v-card>
                </v-col>
                <v-col md="6" sm="12">
                  <v-card tile>
                    <v-card-title class="text-h5">Time Blocking </v-card-title>
                    <v-divider></v-divider>
                    <v-list class="py-0">
                      <v-list-item-group v-model="selectedTimeBlock">
                        <template>
                          <div v-for="(slot, i) in timeSlots" :key="i">
                            <v-list-item
                              @click="selectBookingTime(slot)"
                              :disabled="!isSlotAvailable(slot.availableSlots)"
                            >
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  slot.time
                                }}</v-list-item-title>
                                <v-list-item-subtitle>
                                  {{
                                    isSlotAvailable(slot.availableSlots)
                                      ? `Available Slots: ${slot.availableSlots}`
                                      : `Not Available - Fully booked`
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                          </div>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-row class="mt-5">
          <v-col class="d-flex justify-end">
            <v-btn
              type="submit"
              color="primary"
              outlined
              large
              class="px-10 mr-4"
              @click="$emit('back')"
              >Back</v-btn
            >
            <v-btn
              type="submit"
              color="primary"
              large
              class="px-10"
              @click="submitBookingAndNext()"
              >Next</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
    
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["businessSettingsData"]),
  props: ["customerBooking"],
  data() {
    return {
      selectedTimeBlock: "",
      customerName: "",
      email: "",
      bookingDate: this.format_date(new Date()),
      bookingStartDateTime: null, // Add new property for start date and time
      bookingEndDateTime: null, // Add new property for end date and time
      serviceType: "",
      serviceTypes: ["Haircut", "Massage", "Manicure", "Pedicure"], // Add more service types as needed
      timeSlots: [],
      totalSlots: 5,
      minDate: this.format_date(new Date()),
    };
  },
  methods: {
    isSlotAvailable(availableSlots) {
      return availableSlots > 0;
    },
    bookingDateChanged() {
      this.generateTimeSlots();
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    submitBookingAndNext() {
      let customerBooking = this.customerBooking;
      console.log({ customerBooking });
      customerBooking.startDateTime = moment(this.bookingStartDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      customerBooking.endDateTime = moment(this.bookingEndDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      localStorage.setItem("customerBooking", JSON.stringify(customerBooking));
      this.$emit("changeStep", {
        step: 2,
        customerBooking: this.customerBooking,
      });
    },
    async generateTimeSlots() {
      try {
        const response = await this.$axios.get(
          "/api/generate-time-slots/" + this.bookingDate
        );
        this.timeSlots = response.data.time_slots;
      } catch (error) {
        console.error("Error fetching time slots:", error);
      }
    },
    selectBookingTime(slot) {
      if (!this.bookingDate) {
        console.error("Error: bookingDate is not set.");
        return;
      }

      // Update start and end time based on selected slot
      const [startTime, endTime] = slot.time.split(" - ").map((t) => t.trim());
      this.bookingStartDateTime = this.formatDateTime(
        new Date(this.bookingDate),
        startTime
      );
      this.bookingEndDateTime = this.formatDateTime(
        new Date(this.bookingDate),
        endTime
      );

      console.log("Selected Booking Start Date:", this.bookingStartDateTime);
      console.log("Selected Booking End Date:", this.bookingEndDateTime);
    },

    formatDateTime(date, time) {
      console.log({ time });
      let [hour, minute] = time.split(":");
      let isPM = time.toLowerCase().includes("pm");
      hour = parseInt(hour);
      console.log({ isPM });

      if (isPM && hour !== 12) {
        hour += 12; // Convert PM hours to 24-hour format
      } else if (!isPM && hour === 12) {
        hour = 0; // Handle 12 AM case
      }

      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        hour,
        parseInt(minute),
        0
      );
    },
  },
  mounted() {
    this.generateTimeSlots();
    this.$store.dispatch("fetchBusinessSettings");
  },
};
</script>
    
  
  <style>
.v-date-picker-table .v-btn {
  z-index: auto;
  margin: 0;
  font-size: 17px;
}

#datepicker_1
  > div
  > div.v-picker__body.theme--light
  > div
  > div.v-date-picker-header.theme--light {
  height: 120px;
}
#datepicker_1
  > div
  > div.v-picker__body.theme--light
  > div
  > div.v-date-picker-header.theme--light
  > div
  > div
  > button {
  font-size: 3rem;
}
</style>
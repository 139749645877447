<template>
  <div>
    <date-range-picker
      v-model="dateRange"
      :dateRange="dateRange"
      :append-to-body="true"
      opens="left"
      :autoApply="true"
      @select="updateDatePicker"
      :minDate="'2020-05-02 04:00:00'"
      :ranges="ranges"
      :always-show-calendars="false"
    ></date-range-picker>
  </div>
</template>


<script>
import DateRangePicker from "vue3-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      ranges: [],
    };
  },

  created() {
    this.initRange();
  },
  methods: {
    initRange() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      thisMonthEnd.setHours(23, 59, 59, 999);

      let thisWeekStart = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay()
      );
      let thisWeekEnd = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + (6 - today.getDay())
      );
      thisWeekEnd.setHours(23, 59, 59, 999);

      let lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
      let lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);
      lastYearEnd.setHours(23, 59, 59, 999);
      let allTheTimeStart = new Date(2015, 0, 1); // Start from the epoch (Unix time)
      let allTheTimeEnd = new Date(); // End at a distant future date

      this.ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "This week": [thisWeekStart, thisWeekEnd],
        "This month": [thisMonthStart, thisMonthEnd],
        "Last month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Last year": [lastYearStart, lastYearEnd],
        "All Time": [allTheTimeStart, allTheTimeEnd],
      };
    },
    getSelectedRange(data) {
      for (const key in this.ranges) {
        const [start, end] = this.ranges[key];
        if (
          this.isSameDate(start, data.startDate) &&
          this.isSameDate(end, data.endDate) || key == 'All Time'
        ) {
          return this.$emit("rangeSelected", key);
        }
      }
      return this.$emit("rangeSelected", "Custom Date");
    },
    isSameDate(date1, date2) {
      return date1.toISOString() === date2.toISOString();
    },

    updateDatePicker(data) {
      this.$emit("select", data);
      this.getSelectedRange(data);
    },
  },
};
</script>

<style>
.card-icons {
  font-size: 1.5rem;
  text-align: center;
  margin: a;
}

</style>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="">
        <v-card class="service-card mb-5 justify-space-between">
          <v-card-title><h4>Booking Details</h4></v-card-title>
          <v-divider></v-divider>
          <p style="font-size: 1.5rem" class="pa-4" v-if="customerBooking">
            Service Name:
            <b> {{ customerBooking.selectedService.service_name }}</b>
            <br />
            Price: <b>P{{ customerBooking.selectedService.price }}</b>
            <br />
            From:
            <b>{{
              format_date(customerBooking.startDateTime, "MM-DD-YYYY hh:mm a")
            }}</b>
            <br />
            To:
            <b>{{
              format_date(customerBooking.endDateTime, "MM-DD-YYYY hh:mm a")
            }}</b>
          </p>

          <!-- <div>
          <v-img
            src="https://fakeimg.pl/600x400"
            alt="Service Image"
            class="service-image"
            height="200"
            width="600"
          ></v-img>
        </div> -->
        </v-card>
      </v-col>

      <h1 class="display-5 text-center my-5"></h1>
      <v-col>
        <v-card>
          <v-card-title><h4>Fill up your Contact Information</h4></v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <!-- Booking Form -->
            <v-form @submit.prevent="submit" ref="customerForm">
              <v-text-field
                outlined
                label="First Name"
                hide-details
                class="mb-3"
                :error-messages="errorMessages.firstName"
                v-model="itemForm.firstName"
              ></v-text-field>
              <v-text-field
                outlined
                label="Last Name"
                hide-details
                class="mb-3"
                v-model="itemForm.lastName"
              ></v-text-field>
              <v-select
                outlined
                label="Gender"
                hide-details
                class="mb-3"
                :items="genders"
                v-model="itemForm.gender"
              ></v-select>
              <v-text-field
                outlined
                label="Phone Number"
                hide-details
                class="mb-3"
                v-model="itemForm.phoneNumber"
              ></v-text-field>
              <v-text-field
                outlined
                label="Email Address"
                hide-details
                v-model="itemForm.email"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-text>
            <v-alert
              v-if="Object.keys(errorMessages.errors).length > 0"
              type="error"
              outlined
            >
              <ul>
                <li
                  v-for="(errors, field) in errorMessages.errors"
                  :key="field"
                >
                  <strong>{{ errors[0] }}</strong>
                </li>
              </ul>
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn
          type="submit"
          color="primary"
          outlined
          large
          class="px-10 mr-4"
          @click="$emit('back')"
          >Back</v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          large
          class="px-10"
          @click="saveCustomer()"
          :loading="loading"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
    
<script>
import moment from "moment";
export default {
  props: ["customerBooking"],
  data() {
    return {
      itemForm: {
        id: null,
        firstName: "",
        lastName: "",
        gender: "",
        phoneNumber: "",
        email: "",
      },
      errorMessages: [],
      genders: ["Male", "Female"],
      errorMessages: { errors: [] },
      loading: false
    };
  },
  methods: {
    format_date(value, format) {
      if (value) {
        return moment(String(value)).format(format);
      }
    },
    saveCustomer() {
      this.loading = true;

      // Use $store.dispatch to create or update customer
      let customerBooking = this.customerBooking;
      customerBooking.customer = this.itemForm;
      this.$store
        .dispatch("createBooking", customerBooking)
        .then((res) => {
          this.loading = false;

          console.log({ res });
          this.$router.push({
            path: "/onlinebooking/" + res.data.bookingDetails.reference_id,
          });
        })
        .catch((err) => {
          this.loading = false;

          // console.log({err})
          this.errorMessages = err.response.data;
        });
    },
    formatDateTime(date, time) {
      const [hour, minute] = time.split(":");
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        parseInt(hour),
        parseInt(minute),
        0
      );
    },
  },
  mounted() {
    this.errorMessages.errors = [];
  },
};
</script>
    
  
  <style>
.v-date-picker-table .v-btn {
  z-index: auto;
  margin: 0;
  font-size: 17px;
}

#datepicker_1
  > div
  > div.v-picker__body.theme--light
  > div
  > div.v-date-picker-header.theme--light {
  height: 120px;
}
#datepicker_1
  > div
  > div.v-picker__body.theme--light
  > div
  > div.v-date-picker-header.theme--light
  > div
  > div
  > button {
  font-size: 3rem;
}
</style>
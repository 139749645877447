<template>
  <v-app>
    <v-container>
      <div class="mx-auto mt-10" v-if="!isLoading">
        <div class="text-center">
          <v-icon color="green" style="font-size: 5rem">
            mdi-check-circle
          </v-icon>
          <h1>Your booking has been confirmed.</h1>
          <h4>Reference no: {{ selectedBooking.reference_id }}</h4>
        </div>
        <v-row class="mt-5">
          <v-col>
            <v-card class="mx-auto p-4 m">
              <v-card-title class="py-2">Booking Details</v-card-title>
              <v-divider></v-divider>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td class="font-weight-bold">Service</td>
                    <td>{{ selectedBooking.service.service_name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Full Name</td>
                    <td>
                      {{
                        `${selectedBooking.customer.firstName} ${selectedBooking.customer.lastName}`
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Email</td>
                    <td>{{ `${selectedBooking.customer.email}` }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Price</td>
                    <td>{{ selectedBooking.service.price }}</td>
                  </tr>

                  <tr>
                    <td class="font-weight-bold">Duration</td>
                    <td>{{ selectedBooking.service.duration }}</td>
                  </tr>

                  <tr>
                    <td class="font-weight-bold">Start Date and Time</td>
                    <td>
                      {{
                        format_date(
                          selectedBooking.start_datetime,
                          "MM-DD-YYYY hh:mm a"
                        )
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td class="font-weight-bold">End Date and Time</td>
                    <td>
                      {{
                        format_date(
                          selectedBooking.end_datetime,
                          "MM-DD-YYYY hh:mm a"
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto">
              <v-card-title class="py-2">Contact Details</v-card-title>
              <v-divider></v-divider>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td class="font-weight-bold">Full Name</td>
                    <td>
                      {{
                        `${selectedBooking.customer.firstName} ${selectedBooking.customer.lastName}`
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Email</td>
                    <td>{{ `${selectedBooking.customer.email}` }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Contact Number</td>
                    <td>{{ `${selectedBooking.customer.phoneNumber}` }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>
      
  <script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["selectedBooking"]),
  data() {
    return {
      isLoading: false,
      errorMessages: [],
      genders: ["Male", "Female"],
    };
  },
  methods: {
    format_date(value, format) {
      if (value) {
        return moment(String(value)).format(format);
      }
    },
    formatDateTime(date, time) {
      const [hour, minute] = time.split(":");
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        parseInt(hour),
        parseInt(minute),
        0
      );
    },
    fetchBookingByRefId() {
      this.isLoading = true;
      this.$store
        .dispatch("fetchBookingByRefId", this.$route.params.id)
        .then((res) => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.fetchBookingByRefId();
  },
};
</script>
      
    
    <style>
.v-date-picker-table .v-btn {
  z-index: auto;
  margin: 0;
  font-size: 17px;
}

#datepicker_1
  > div
  > div.v-picker__body.theme--light
  > div
  > div.v-date-picker-header.theme--light {
  height: 120px;
}
#datepicker_1
  > div
  > div.v-picker__body.theme--light
  > div
  > div.v-date-picker-header.theme--light
  > div
  > div
  > button {
  font-size: 3rem;
}
</style>
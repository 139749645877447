  <template>
  <div class="pt-5">
    <h1 class="text-center">Team Member Page List</h1>

    <v-card class="mx-5 mt-5">
      <v-card-title class="d-flex justify-space-between">
        Team
        <v-btn color="primary" dark class="mb-2" @click="openAddTeam()">
          New Team Member
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :headers="headers" :items="items">
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.id }}</td>
            <td>{{ item.fullName }}</td>
            <td>{{ item.permission }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.email }}</td>
            <td>
              <v-icon
                small
                class="mx-1 green--text"
                @click="openEditTeam(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon small class="red--text" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </td>
          </tr>
          <v-dialog v-model="deleteDialog" max-width="500px">
            <v-card>
              <v-card-text>
                Do you want to delete this team member?
              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn color="error" @click="deleteItemConfirmed">Yes</v-btn>
                <v-btn color="primary" @click="closeDeleteDialog">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="addEditdialog" max-width="500px">
      <v-card>
        <v-card-title v-if="actionDialog == 'add'"> Add New Team </v-card-title>
        <v-card-title v-if="actionDialog == 'edit'">
          Edit Team List
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            label="Full Name"
            hide-details=""
            class="mb-3"
            v-model="itemForm.fullName"
          ></v-text-field>
          <v-text-field
            outlined
            label="Phone Number"
            hide-details=""
            class="mb-3"
            v-model="itemForm.phone"
          ></v-text-field>
          <v-text-field
            outlined
            label="Email Address"
            hide-details=""
            v-model="itemForm.email"
          ></v-text-field>
          <v-select
            outlined
            label="Permission "
            hide-details=""
            class="mb-3"
            :items="genders"
            v-model="itemForm.permission"
          ></v-select>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn color="secondary" @click="closeDialog()">Cancel</v-btn>
          <v-btn color="primary" @click="saveTeam()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-text class="pt-5">
          Do you want to delete this team member?
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="error" @click="deleteItemConfirmed">Yes</v-btn>
          <v-btn color="primary" @click="closeDeleteDialog">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

  <script>
export default {
  data: () => ({
    deleteDialog: false,
    itemToDelete: null,
    genders: ["Admin", "Doctor", "Employee"],
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "fullName",
      },
      { text: "Permission ", value: "permission", sortable: false },
      { text: "Phone-Number", value: "phone", sortable: false },
      { text: "Email-Address", value: "email", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    items: [
    
    ],
    itemForm: {
      id: null,
      fullName: "",
      permission: "",
      phone: "",
      email: "",
    },
    addEditdialog: false,
    actionDialog: "",
  }),
  methods: {
    openAddTeam() {
      this.actionDialog = "add";
      this.addEditdialog = true;
    },
    openEditTeam(team) {
      this.actionDialog = "edit";
      this.addEditdialog = true;
      this.itemForm.id = team.id;
      this.itemForm.fullName = team.fullName;
      this.itemForm.permission = team.permission;
      this.itemForm.email = team.email;
      this.itemForm.phone = team.phone;
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItemConfirmed() {
      const index = this.items.indexOf(this.itemToDelete);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
      this.deleteDialog = false;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    saveTeam() {
      if (this.actionDialog === "edit") {
        const index = this.items.findIndex(
          (item) => item.id === this.itemForm.id
        );
        if (index !== -1) {
          this.items[index].fullName = this.itemForm.fullName;
          this.items[index].permission = this.itemForm.permission;
          this.items[index].phone = this.itemForm.phone;
          this.items[index].email = this.itemForm.email;
        }
      } else if (this.actionDialog === "add") {
        this.items.push({
          id: Date.now(),
          fullName: this.itemForm.fullName,
          permission: this.itemForm.permission,
          phone: this.itemForm.phone,
          email: this.itemForm.email,
        });
      }

      this.itemForm.fullName = "";
      this.itemForm.permission = "";
      this.itemForm.phone = "";
      this.itemForm.email = "";

      this.addEditdialog = false;
    },
    closeDialog() {
      this.addEditdialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
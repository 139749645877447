<template>
  
    <v-navigation-drawer permanent app >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 py-2">
            Corado Aesthetics
          </v-list-item-title>
        
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
        id="main-nav"
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

</template>

<script>
  export default {
    data () {
      return {
        items: [
          { title: 'Dashboard', icon: 'mdi mdi-web', path: "/"},
          { title: 'Customer', icon: 'mdi mdi-account-details', path:"/customer" },
          { title: 'Services', icon: 'mdi mdi-face-man-shimmer' , path: "/services" },
          { title: 'Team', icon: 'mdi mdi-account-group', path: "/team" },
          { title: 'Appointments', icon: 'mdi mdi-calendar-badge-outline', path: "/appointments" },
          { title: 'Booking Settings', icon: 'mdi mdi-cloud-outline', path: "/booking-settings" },
          { title: 'Report', icon: 'mdi mdi-chart-bar', path: "/report" },
        ],
        right: null,
      }
    },
  }
</script>

<style scope>
#main-nav  .v-list-item--active,
#main-nav  .v-list-item--link:hover {
  border-radius: 10px;
  background: #DB9A42;
  color: #fff !important;
}

</style>
import axios from "../../plugins/axios";
// state
export const state = () => ({
  services_categories: [],
});

// getters
export const getters = {
  services_categories: (state) => state.services_categories,
};

// actions
export const actions = {
  fetchServicesCategory({ commit }) {
    axios
      .get("/api/service-categories")
      .then((response) => {
        commit("SET_SERVICE_CATEGORIESS_CATEGORY", response.data);
      })
      .catch((error) => {
        console.error("Error fetching service-categories:", error);
      });
  },
  searchServicesCategory({ commit }, search) {
    axios
      .get(`/api/service-categories/search?query=${search}`)
      .then((response) => {
        commit("SET_SERVICE_CATEGORIESS_CATEGORY", response.data);
      })
      .catch((error) => {
        console.error("Error fetching service-categories:", error);
      });
  },
  createServiceCategory({ commit }, serviceCategoryData) {
    return axios.post("/api/service-categories", serviceCategoryData);
  },
  updateServiceCategory({ commit }, serviceCategoryData) {
    return axios.put(`/api/service-categories/${serviceCategoryData.id}`, serviceCategoryData);
  },
  deleteServiceCategory({ commit }, serviceId) {
    axios
      .delete(`/api/service-categories/${serviceId}`)
      .then(() => {
        commit("DELETE_SERVICE_CATEGORIES", serviceId);
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  },
};

// mutations
export const mutations = {
  SET_SERVICE_CATEGORIESS_CATEGORY(state, services_categories) {
    state.services_categories = services_categories;
  },
  ADD_SERVICE_CATEGORIES(state, service) {
    state.services_categories.push(service);
  },
  UPDATE_SERVICE_CATEGORIES(state, updatedTask) {
    const index = state.services_categories.findIndex(
      (service) => service.id === updatedTask.id
    );
    if (index !== -1) {
      state.services_categories.splice(index, 1, updatedTask);
    }
  },
  DELETE_SERVICE_CATEGORIES(state, serviceId) {
    state.services_categories = state.services_categories.filter(
      (service) => service.id !== serviceId
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-app>
      <HeaderComponent/>
      <SidebarComponent/>

    <v-main>
      <router-view> </router-view>
    </v-main>
  </v-app>
</template>

<script>
import HeaderComponent from './components/Layouts/HeaderComponent';
import SidebarComponent from './components/Layouts/SidebarComponent'


export default {
  name: 'App',

  components: {
    HeaderComponent,
    SidebarComponent,
  },

  data: () => ({
    //
  }),
  mounted() {
    this.$store.dispatch('fetchBusinessSettings')
  }

};
</script>

<template>
  <v-container fluid>
   <v-row>
    <v-col class="d-flex justify-space-between align-center">
      <h1 class="mb-2">Bookings Settings</h1>
      <v-btn class="px-4" :loading="isLoading" color="primary" @click="updateBusinessSettings()">Update Settings</v-btn>
    </v-col>
   </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Business Information </v-card-title>
          <v-card-text class="d-flex justify-space-between">
            <v-form
              @submit.prevent="submit"
              ref="customerForm"
              style="width: 100%"
            >
              <v-text-field
                v-model="businessSettings.business_name"
                outlined
                label="Business Name"
                hide-details
                class="mb-3"
              ></v-text-field>
              <v-text-field
                v-model="businessSettings.website"
                outlined
                label="Website"
                hide-details
                class="mb-3"
              ></v-text-field>
              <v-text-field
                v-model="businessSettings.business_email"
                outlined
                class="mb-3"
                label="Business Email"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="businessSettings.facebook_link"
                outlined
                label="Facebook Link"
                hide-details
                class="mb-3"
              ></v-text-field>
              <v-text-field
                v-model="businessSettings.contact_number"
                outlined
                label="Contact Number"
                hide-details
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title> Booking Settings </v-card-title>
          <v-card-text class="d-flex justify-space-between">
            <v-form
              @submit.prevent="submit"
              ref="customerForm"
              style="width: 100%"
            >
              <v-select
                v-model="businessSettings.opening_time"
                :items="hours"
                label="Opening Time"
                append-outer-icon="mdi-clock"
                clearable
                outlined
                hide-details
                class="mb-3"
                :menu-props="{ closeOnContentClick: false }"
                @change="handleCustomHour"
              >
                <template v-slot:append-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="customHour"
                        label="Enter custom hour"
                        placeholder="HH:MM"
                        @keyup.enter="addCustomHour"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
              <v-select
                v-model="businessSettings.closing_time"
                :items="hours"
                label="Closing Time"
                append-outer-icon="mdi-clock"
                clearable
                outlined
                hide-details
                class="mb-3"
                :menu-props="{ closeOnContentClick: false }"
                @change="handleCustomHour"
              >
                <template v-slot:append-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="customHour"
                        label="Enter custom hour"
                        placeholder="HH:MM"
                        @keyup.enter="addCustomHour"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
              <v-text-field
              v-model="businessSettings.max_booking_per_slot"
                outlined
                label="Maximum Booking Per Slot"
                hide-details
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
        
        </v-card>
      </v-col>
    </v-row>

    <v-card-text>
          <v-alert
            v-if="Object.keys(errorMessages.errors).length > 0"
            type="error"
            outlined
          >
            <ul>
              <li v-for="(errors, field) in errorMessages.errors" :key="field">
                <strong>{{ errors[0] }}</strong>
              </li>
            </ul>
          </v-alert>
        </v-card-text>
  </v-container>
</template>
  
<script>
import { mapGetters } from "vuex";

export default {
  computed: mapGetters(["businessSettingsData"]),
  data() {
    return {
      selectedHour: "07:00 AM",
      customHour: "",
      hours: this.generateHours(),
      businessSettings: {
        business_name: "",
        website: "",
        business_email: "",
        facebook_link: "",
        contact_number: "",
        opening_time: "",
        closing_time: "",
        max_booking_per_slot: "",
      },
      isLoading: false,
      errorMessages: { errors: [] },

    };
  },
  methods: {
    generateHours() {
      const hours = [];
      const periods = ["AM", "PM"];
      periods.forEach((period) => {
        for (let i = 1; i <= 12; i++) {
          const hour = i < 10 ? `0${i}` : i;
          hours.push(`${hour}:00 ${period}`);
          hours.push(`${hour}:30 ${period}`);
        }
      });
      return hours;
    },
    addCustomHour() {
      const timePattern = /^((0[1-9]|1[0-2]):([0-5][0-9]) (AM|PM))$/;
      if (
        this.customHour &&
        timePattern.test(this.customHour) &&
        !this.hours.includes(this.customHour)
      ) {
        this.hours.push(this.customHour);
        this.selectedHour = this.customHour;
        this.customHour = "";
      }
    },
    handleCustomHour(value) {
      const timePattern = /^((0[1-9]|1[0-2]):([0-5][0-9]) (AM|PM))$/;
      if (!this.hours.includes(value) && timePattern.test(value)) {
        this.addCustomHour();
      }
    },
    updateBusinessSettings() {
      this.isLoading = true;
     this.$store.dispatch('updateBusinessSettings', this.businessSettings )
        .then((response) => {
          this.$swal.fire("Settings successfully updated!", "", "success");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errorMessages = error.response.data;
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch('fetchBusinessSettings')
    .then(()=>{
      this.businessSettings = this.businessSettingsData
    })
  },
};
</script>

<style scoped>
.v-list-item__content {
  padding: 0;
}
</style>
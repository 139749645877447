<template>
  <v-dialog v-model="dialog" :width="isViewInvoice ? 800 : 1500">
    <v-card class="pa-4">
      <v-card-title
        class="text-h4 font d-flex justify-space-between mt-0 pt-0 px-0"
      >
        <div>
          {{cardTitle}}
        </div>

        <div class="d-flex align-center justify-space-between">
          <v-btn
            fab
            elevation="0"
            class="mr-4"
            color="primary"
            :loading="isLoading"
            v-if="appointActionType == 'view'"
            @click="openEdit()"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            v-if="appointActionType == 'edit'"
            @click="appointActionType = 'view'"
          >
            Back
          </v-btn>
          <v-btn elevation="0" icon v-else @click="dialog = false">
            <v-icon x-large style="font-size: 55px">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>

      <AppointmentCreate
        ref="appt_create"
        :appointmentData="appointmentData"
        @closeDialog="
          isLoading = false;
          $emit('closeDialog');
        "
        v-if="isNotViewing"
      />
      <AppointmentDetails
        :appointmentData="appointmentData"
        @checkoutConfirmation="appointActionType = 'invoice'"
        v-if="!isNotViewing && !isViewInvoice"
        @closeDialog="
          $emit('closeDialog');
        "
      />

      <AppointmentInvoice
        @back="appointActionType = 'view'"
        :appointmentData="appointmentData"
        v-if="isViewInvoice"
      />
    </v-card>
  </v-dialog>
</template>
        
<script>
import AppointmentCreate from "./AppointmentCreate.vue";
import AppointmentDetails from "./AppointmentDetails.vue";
import AppointmentInvoice from "./AppointmentInvoice.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: ["appointmentData", "actionType"],
  computed: {
    ...mapGetters(["services_categories", "services"]),
    isNotViewing() {
      return (
        this.appointActionType == "edit" || this.appointActionType == "create"
      );
    },
    isViewInvoice() {
      return this.appointActionType == "invoice";
    },
    cardTitle() {
      switch(this.appointActionType) {
        case 'edit':
          return 'Update Appointment'
        case 'invoice':
          return 'Checkout' 
        default : 
          return 'Appointment Details'
      }
    }
  },
  components: {
    AppointmentCreate,
    AppointmentDetails,
    AppointmentInvoice,
  },
  data() {
    return {
      dialog: true,
      appointActionType: this.actionType,
      isLoading: false,
    };
  },
  methods: {
    openEdit() {
      // eslint-disable-next-line vue/no-mutating-props
      this.appointActionType = "edit";
    },
    isBookingMatchSlot() {
      let existing =
        this.moment(this.customerBooking.startDateTime, "h:mma") +
        " - " +
        this.moment(this.customerBooking.endDateTime, "h:mma");

      let index = this.timeSlots.findIndex((item) => {
        console.log("slot: ", item.time);
        console.log("existing: ", existing);
        return item.time == existing;
      });
      this.selectedTimeBlock = index;
      console.log(this.selectedTimeBlock);
    },
    test() {
      return (
        this.moment(this.customerBooking.startDateTime, "hh:mma") +
        " - " +
        this.moment(this.customerBooking.endDateTime, "hh:mma")
      );
    },
    isSlotAvailable(availableSlots) {
      return availableSlots > 0;
    },
    bookingDateChanged() {
      this.generateTimeSlots();
    },
    moment(value, format) {
      return moment(String(value)).format(format);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    back() {
      if (this.step == 2) this.step--;
    },
    SubmitAndSaveBooking() {
      this.isLoading = true;
      this.$refs.appt_create.SubmitAndSaveBooking().then(() => {
        this.isLoading = false;
      });
    },
    async generateTimeSlots() {
      try {
        const response = await this.$axios.get(
          "/api/generate-time-slots/" + this.bookingDate
        );
        this.timeSlots = response.data.time_slots;
        this.isBookingMatchSlot();
      } catch (error) {
        console.error("Error fetching time slots:", error);
      }
    },
    selectBookingTime(slot) {
      if (!this.bookingDate) {
        console.error("Error: bookingDate is not set.");
        return;
      }

      this.customerBooking.startDateTime = this.formatDateTime(
        new Date(this.bookingDate),
        slot.time.trim(),
        0
      );
      this.customerBooking.endDateTime = this.formatDateTime(
        new Date(this.bookingDate),
        slot.time.trim(),
        1
      );

      console.log("Selected Booking Date:", this.customerBooking.startDateTime);
      console.log("Selected Booking Date:", this.customerBooking.endDateTime);
    },
    formatDateTime(date, time, index) {
      time = time.split("-");
      const newTime = time[index];
      const [hour, minute] = newTime.split(":"); // Extract hour and AM/PM indicator
      let hourValue = parseInt(hour);

      // Adjust hour for PM times
      if (newTime.toLowerCase().includes("pm") && hourValue < 12) {
        hourValue += 12;
      }

      return moment(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          hourValue,
          parseInt(minute)
        )
      ).format("YYYY-MM-DD HH:mm");
    },
  },
  mounted() {
  },
};
</script>
        
      
      <style>
.v-date-picker-table .v-btn {
  z-index: auto;
  margin: 0;
  font-size: 17px;
}

#datepicker_1
  > div
  > div.v-picker__body.theme--light
  > div
  > div.v-date-picker-header.theme--light {
  height: 120px;
}
#datepicker_1
  > div
  > div.v-picker__body.theme--light
  > div
  > div.v-date-picker-header.theme--light
  > div
  > div
  > button {
  font-size: 2rem;
}
</style>
<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h1 class="headline">Payments Report</h1>
      </v-card-title>
      <v-data-table :headers="headers" :items="payments" class="elevation-1">
        <template v-slot:[`item.paid_at`]="{ item }">
          {{ new Date(item.paid_at).toLocaleString() }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ new Date(item.created_at).toLocaleString() }}
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          {{ new Date(item.updated_at).toLocaleString() }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
  
  <script>
import axios from "axios";

export default {
  data() {
    return {
      payments: [],
      headers: [
        { text: "ID", value: "payment.id" },
        { text: "Customer ID", value: "payment.customer.id" },
        { text: "Customer email", value: "payment.customer.email" },
        { text: "Services Category", value: "payment.booking.service.category.name" },
        { text: "Services Name", value: "payment.booking.service.service_name" },
        { text: "Amount", value: "payment.amount" },
        { text: "Paid At", value: "payment.paid_at" },
        { text: "Status", value: "payment.status" },
        { text: "Created At", value: "payment.created_at" },
        { text: "Booking Date", value: "payment.booking.start_datetime" },
      ],
    };
  },
  created() {
    this.fetchPayments();
  },
  methods: {
    fetchPayments() {
        axios.get("/api/payments")
        .then((response) => {
          this.payments = response.data;
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the payments data:",
            error
          );
        });
    }
  },
};
</script>
  
  <style>
.headline {
  font-weight: bold;
}
</style>
  
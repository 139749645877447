<template>
  <v-card elevation="0">
    <div class="invoice-box"  id="printMe">
      <table cellpadding="0" cellspacing="0">
        <tr class="top">
          <td colspan="4">
            <table>
              <tr>
                <td class="title">
                  <img src="@/assets/corado-logo.jpg" height="130px" />
                </td>

                <td>
                  <!-- Invoice #: {{ invoice.invoiceNumber }}<br /> -->
                  Date: {{ moment(invoice.date).format("MM/DD/YYYY hh:mm a") }}
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="information">
          <td colspan="4">
            <table>
              <tr>
                <td>
                  {{ customerName }}<br />
                  {{ appointmentData.data.customer.email }}<br />
                  {{ appointmentData.data.customer.address ?? "N/A" }} <br />
                  {{ appointmentData.data.customer.phoneNumber }}
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="heading">
          <td colspan="2">Description</td>
          <td class="text-center">Quantity</td>
          <td>Price</td>
        </tr>
        <tr class="item">
          <td colspan="2">
            <b>{{ appointmentData.data.service.service_name }}</b> <br />
            {{ appointmentData.data.service.description }}
          </td>

          <td class="text-center">1</td>
          <td>PHP {{ appointmentData.data.service.price }}</td>
        </tr>

        <tr class="total">
          <td colspan="3"></td>
          <td class="">
            Amount Due: PHP {{ appointmentData.data.service.price }}
          </td>
        </tr>
      </table>
    </div>

    <v-row class="mt-5">
      <v-col class="d-flex justify-end" v-if="!isNotViewing">
        <v-btn
          type="submit"
          outlined
          color="red"
          class="px-10 mr-4"
          @click="$emit('back')"
        >
          Back</v-btn
        >
        <v-btn
          type="submit"
          color="green"
          dark
          class="px-10 mr-4"
          @click="checkoutConfirmation()"
          v-if="appointmentData.data.payment.status !='completed' && paymentStatus == 0"
        >
          Save & Pay
        </v-btn>
        <v-btn
          type="submit"
          color="green"
          dark
          class="px-10 mr-4"
          v-else
          @click="print()"
          v-print="'#printMe'"
        >
          <v-icon left>mdi-printer</v-icon>
          Print
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
  
  <script>
import axios from "axios";
import print from 'vue-print-nb'

export default {
  directives: {
    print   
},
  props: ["appointmentData"],
  computed: {
    customerName() {
      let customer = this.appointmentData.data.customer;
      return customer.lastName + ", " + customer.firstName;
    },
  },
  data() {
    return {
      dialog: true,
      invoice: {
        invoiceNumber: "",
        date: "",
      },
      paymentStatus: 0,
    };
  },
  methods: {
    genInvoiceNumber() {
      axios.get("api/invoices/generate").then((res) => {
        this.invoice.invoiceNumber = res.data.invoiceNumber;
        this.invoice.date = res.data.date;
      });
    },
    checkoutConfirmation() {

      let data = {
        user_id: this.appointmentData.data.customer.id,
        service_id: this.appointmentData.data.service.id,
        amount: this.appointmentData.data.service.price,
        invoice_no: this.invoice.invoiceNumber,
        booking_id: this.appointmentData.data.id,
      };
      axios.post("/api/payments", data).then((res) => {
        this.paymentStatus = 1;
        this.$swal.fire(res.data.message, "", "success");
        location.reload();
      });
    },
  },
  mounted() {
    if (!this.appointmentData.data.payment) {
      this.genInvoiceNumber();
    } else {
      const { payment } = this.appointmentData.data;
      this.invoice.invoiceNumber = payment.invoice_no;
      this.invoice.date = payment.date;
    }
  },
};
</script>
          
        
  
<style>
#appointment-content > h4,
#appointment-content > div > div > div:nth-child(1) {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

#appointment-content > div > div > div:nth-child(2) {
  font-size: 24px;
}
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
  padding-top: 20px;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}
</style>
  
  
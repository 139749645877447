import axios from "../../plugins/axios";

// state
export const state = () => ({
  bookings: [],
  selectedBooking: null
});

// getters
export const getters = {
  bookings: (state) => state.bookings,
  selectedBooking: (state) => state.selectedBooking,
};

// actions
export const actions = {
  async fetchBookings({ commit }) {
    const response = await axios.get("/api/bookings");
    commit("SET_BOOKINGS", response.data);
    return response;
  },
  async fetchBookingByRefId({ commit },ref_id) {
    const response = await axios.get("/api/bookings/ref/" + ref_id);
    commit("SET_SELECTED_BOOKING", response.data);
    return response;
  },
  async createBooking({ commit }, bookingData) {
    return await axios.post("/api/bookings", bookingData);
  },
  async updateBookingStatus({ commit },data) {
    const response = await axios.put(`/api/bookings/status/${data.bookingId}`,data);
    return response;
  },
  async deleteBooking({ commit }, bookingId) {
    return await axios.delete(`/api/bookings/${bookingId}`);
  },
};

// mutations
export const mutations = {
  SET_BOOKINGS(state, bookings) {
    state.bookings = bookings;
  },
  SET_SELECTED_BOOKING(state, selectedBooking) {
    state.selectedBooking = selectedBooking;
  },
  ADD_BOOKING(state, booking) {
    state.bookings.push(booking);
  },
  DELETE_BOOKING(state, bookingId) {
    state.bookings = state.bookings.filter(
      (booking) => booking.id !== bookingId
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

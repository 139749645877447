<template>
  <div class="pt-5">
    <v-card-text class="d-flex justify-space-between">
      <h1 class="text-center">Services</h1>

      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="openAddServiceCategory()"
      >
        Add Category
      </v-btn>
    </v-card-text>

    <v-card class="mx-5 mt-5" v-if="services_categories.length == 0">
      <v-card-title> No Services Available </v-card-title>
    </v-card>
    <div v-else>
      <v-card
        class="mx-5 mt-5"
        v-for="(category, index) in services_categories"
        :key="index"
      >
        <v-card-title class="d-flex justify-space-between py-2">
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      class="mb-2"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>Menu</span>
                </v-tooltip>
              </template>
              <v-list dense class="py-0">
                <v-list-item @click="opeEditServiceCategory(category)">
                  <v-list-item-title>
                    <v-icon left>mdi-pencil</v-icon>Edit Category
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteCategory(category)">
                  <v-list-item-title>
                    <v-icon left>mdi-delete</v-icon>Delete Category
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            {{ category.name }}
          </div>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="openAddService(category.id)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-list subheader two-line v-if="category.services.length > 0">
          <v-list-item
            v-for="(service, index2) in category.services"
            :key="index2"
            :style="`border-left: 5px solid ${service.color};`"
          >
            <v-list-item-avatar>
              <v-icon :style="`background-color: ${service.color};`" color="white"> mdi-tag</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ service.service_name }}</v-list-item-title>

              <v-list-item-subtitle>
                Price: {{ service.price }} <br />
                Duration: {{ service.duration }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-menu offset-y>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        class="mb-2"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <span>Menu</span>
                  </v-tooltip>
                </template>
                <v-list dense class="py-0">
                  <v-list-item @click="openEditService(service)">
                    <v-list-item-title>
                      <v-icon left>mdi-pencil</v-icon>Edit Service
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteService(service)">
                    <v-list-item-title>
                      <v-icon left>mdi-delete</v-icon>Delete Service
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-text v-else> No Services Available </v-card-text>
      </v-card>
    </div>

    <v-dialog v-model="addEditdialog" max-width="500px" persistent>
      <v-card v-if="type == 'servicesCategory'">
        <v-card-title v-if="actionDialog == 'add'"> New Category </v-card-title>
        <v-card-title v-if="actionDialog == 'edit'">
          Edit Category
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            label="Category Name"
            hide-details=""
            class="mb-3"
            v-model="itemCategoryForm.name"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn color="secondary" @click="closeDialog()">Cancel</v-btn>
          <v-btn color="primary" @click="saveServicesCategory()">Save</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="type == 'services'">
        <v-card-title v-if="actionDialog == 'add'"> New Services </v-card-title>
        <v-card-title v-if="actionDialog == 'edit'">
          Edit Services
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            label="Service Name"
            hide-details=""
            class="mb-3"
            v-model="itemForm.service_name"
          ></v-text-field>
          <v-textarea
            outlined
            label="Description"
            hide-details=""
            class="mb-3"
            v-model="itemForm.description"
          ></v-textarea>
          <v-select
            outlined
            label="Duration"
            hide-details=""
            class="mb-3"
            :items="durations"
            v-model="itemForm.duration"
          ></v-select>
          <v-text-field
            outlined
            label="Price"
            hide-details=""
            class="mb-3"
            v-model="itemForm.price"
            type="number"
          ></v-text-field>
          <v-text-field
            v-model="itemForm.color"
            hide-details
            class="ma-0 pa-0"
            solo
            @click="menu = true"
          >
            <template v-slot:append>
              <v-menu
                v-model="menu"
                left
               
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div :style="swatchStyle" v-on="on" />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker  mode="hexa" v-model="itemForm.color" flat />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn color="secondary" @click="closeDialog()">Cancel</v-btn>
          <v-btn color="primary" @click="saveServices()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["services_categories"]),
    swatchStyle() {
      const {  itemForm, menu } = this
      return {
        backgroundColor: itemForm.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },
  data: () => ({
    deleteDialog: false,
    itemToDelete: null,
    durations: [
      "30 mins",
      "45 mins",
      "1 hour",
      "1 hr 15 mins",
      "1 hr 30 mins",
      "1 hr 45 mins",
      "2 hrs",
      "2 hrs 15 mins",
      "2 hrs 30 mins",
      "2 hrs 45 mins",
    ],
    items: [],
    itemForm: {
      service_name: "",
      price: "",
      description: "",
      duration: "",
      category_id: "",
      color: '#1976D2FF',
    },
		mask: '!#XXXXXXXX',
		menu: false,
    itemCategoryForm: {
      name: "",
    },
    addEditdialog: false,
    actionDialog: "",
    type: "", // for services or servicesCategory
  }),
  methods: {
    openAddServiceCategory() {
      this.actionDialog = "add";
      this.addEditdialog = true;
      this.type = "servicesCategory";
    },
    opeEditServiceCategory(category) {
      this.actionDialog = "edit";
      this.addEditdialog = true;
      this.type = "servicesCategory";
      this.itemCategoryForm.id = category.id;
      this.itemCategoryForm.name = category.name;
    },
    openAddService(category_id) {
      this.actionDialog = "add";
      this.addEditdialog = true;
      this.itemForm.category_id = category_id;
      this.type = "services";
    },

    openEditService(services) {
      this.actionDialog = "edit";
      this.addEditdialog = true;
      this.type = "services";
      this.itemForm.id = services.id;
      this.itemForm.category_id = services.category_id;
      this.itemForm.service_name = services.service_name;
      this.itemForm.description = services.description;
      this.itemForm.price = services.price;
      this.itemForm.duration = services.duration;
      this.itemForm.color = services.color;
    },
    deleteCategory(category) {
      if (category.services.length > 0) {
        alert(
          "Warning! Unable to delete this category. \nRemove all the services inside of this category to proceed."
        );
        return;
      }
      let result = confirm(
        "Are you sure want to delete this category? \nThis action cannot be undone."
      );
      if (result) {
        this.$store.dispatch("deleteServiceCategory", category.id).then(() => {
          this.$store.dispatch("fetchServicesCategory");
        });
      }
    },
    deleteService(service) {
      let result = confirm(
        "Are you sure want to delete this services? \nThis action cannot be undone."
      );
      if (result) {
        this.$store.dispatch("deleteService", service.id).then(() => {
          this.$store.dispatch("fetchServicesCategory");
        });
      }
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    saveServices() {
      if (
        this.itemForm.service_name == "" ||
        this.itemForm.price == "" ||
        this.price == ""
      ) {
        alert("Please fill up all the fields");
        return;
      }

      if (this.actionDialog === "add") {
        this.$store.dispatch("createService", this.itemForm).then(() => {
          this.$store.dispatch("fetchServicesCategory");
        });
      } else {
        this.$store.dispatch("updateService", this.itemForm).then(() => {
          this.$store.dispatch("fetchServicesCategory");
        });
      }

      this.itemForm.service_name = "";
      this.itemForm.price = "";
      this.itemForm.duration = "";
      this.itemForm.description = "";
      this.itemForm.category = "";
      this.addEditdialog = false;
    },

    saveServicesCategory() {
      if (this.itemCategoryForm.name == "") {
        alert("Error: Please enter Category name");
        return;
      }

      if (this.actionDialog == "add") {
        this.$store
          .dispatch("createServiceCategory", this.itemCategoryForm)
          .then(() => {
            this.$store.dispatch("fetchServicesCategory");
            this.addEditdialog = false;
          });
      } else {
        this.$store
          .dispatch("updateServiceCategory", this.itemCategoryForm)
          .then(() => {
            this.$store.dispatch("fetchServicesCategory");
            this.addEditdialog = false;
          });
      }
      this.itemCategoryForm.name = "";
    },
    closeDialog() {
      this.addEditdialog = false;
    },
  },
  mounted() {
    this.$store.dispatch("fetchServicesCategory");
  },
};
</script>
<template>
  <v-app-bar app color="primary" dark >
    <div class="d-flex align-center">
      <v-img
        alt=""
        class="shrink mr-2"
        contain
        src=""
        transition="scale-transition"
        width="40"
      />
      <v-img
        alt=""
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="100"
        src=""
        width="100"
      />
    </div>

    <v-spacer></v-spacer>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon large v-bind="attrs" v-on="on">
          <!-- <v-avatar size="32px" tile>
                                <img src="https://cdn.vuetifyjs.com/images/logos/logo.svg" alt="Vuetify">
                            </v-avatar> -->

          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item link tile>
          <v-list-item-title dense>
            <v-icon>mdi-account-cog</v-icon> Change Password
          </v-list-item-title>
        </v-list-item>
        <v-list-item link tile @click="logout()">
          <v-list-item-title dense>
            <v-icon>mdi-logout</v-icon> Log out
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>


<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('logoutUser');
    }
  },  
}
</script>
<template>
  <div class="mx-5 mt-5">
    <v-card-text class="d-flex justify-space-between">
      <div>
        <h1 class="mb-3">Appointments</h1>
        <div>
          Online Booking Link:
          <a href="http://localhost:8080/onlinebooking" target="_blank"
            >http://localhost:8080/onlinebooking</a
          >
        </div>
      </div>

     <div>
      <v-btn color="blue" dark class="mb-2 mr-3" @click="openAddAppointment()">
        <v-icon left>mdi-cog</v-icon>
        Settings
      </v-btn>
      <v-btn color="primary" dark class="mb-2" @click="openAddAppointment()">
        <v-icon left>mdi-plus</v-icon>
        Add Appointment
      </v-btn>
     </div>
    </v-card-text>

    <v-card>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setDateToday()"
          >
            Today
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>

      <v-card-text class="d-flex justify-end py-3">
        <v-btn-toggle v-model="viewType">
          <v-btn small outlined>
            <v-icon>mdi-table</v-icon>
          </v-btn>

          <v-btn small outlined>
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-card-text>

      <v-card class="bordered">
        <v-card-title class="d-flex justify-space-between">{{
          this.getTitle
        }}</v-card-title>

        <appointment-calendar
          v-if="isCalendarView"
          ref="calendarRef"
          :bookings="bookings"
          :type="type"
          :key="componentKey"
          @openAppointmentDetails="openAppointmentDetails"
        />

        <appointment-table
          v-else
          :type="type"
          :key="componentKey"
          :filteredBookings="filteredBookings"
          @openAppointmentDetails="openAppointmentDetails"
        ></appointment-table>
      </v-card>
    </v-card>

    <!-- Modaal -->
    <AppointmentModal
      v-if="appointmentModal"
      :appointmentData="appointmentDetails"
      :key="componentKey"
      @closeDialog="closeAppointmentModal"
      :actionType="actionType"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppointmentCalendar from "@/components/Appointment/AppointmentCalendar.vue";
import AppointmentTable from "@/components/Appointment/AppointmentTable.vue";
import AppointmentModal from "@/components/Appointment/AppointmentModal.vue";
import moment from "moment";
export default {
  components: {
    AppointmentCalendar,
    AppointmentTable,
    AppointmentModal,
  },
  computed: {
    ...mapGetters(["bookings"]),
    getTitle() {
      switch (this.type) {
        case "day":
          return moment(this.currentDate).format("MMM Do, YYYY");
        case "week":
          const startOfWeek = moment(this.currentDate).startOf("week");
          const endOfWeek = moment(this.currentDate).endOf("week");
          return (
            startOfWeek.format("MMM Do") +
            " - " +
            endOfWeek.format("MMM Do, YYYY")
          );
        case "month":
          return moment(this.currentDate).format("MMMM YYYY");
        case "4day":
          const endOf4Days = moment(this.currentDate).add(3, "days");
          return (
            moment(this.currentDate).format("MMM Do") +
            " - " +
            endOf4Days.format("MMM Do, YYYY")
          );
        default:
          return "All bookings";
      }
    },
    filteredBookings() {
      let filtered = [];

      switch (this.type) {
        case "day":
          filtered = this.bookings.filter((booking) =>
            this.isSameDay(new Date(booking.start_datetime), this.currentDate)
          );
          break;
        case "week":
          const startOfWeek = new Date(this.currentDate);
          startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Get the start of the current week
          const endOfWeek = new Date(startOfWeek);
          endOfWeek.setDate(endOfWeek.getDate() + 6); // Get the end of the current week

          filtered = this.bookings.filter((booking) => {
            const bookingDate = new Date(booking.start_datetime);
            return bookingDate >= startOfWeek && bookingDate <= endOfWeek;
          });
          break;
        case "month":
          const startOfMonth = new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            1
          );
          const endOfMonth = new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth() + 1,
            0
          );

          filtered = this.bookings.filter((booking) => {
            const bookingDate = new Date(booking.start_datetime);
            return bookingDate >= startOfMonth && bookingDate <= endOfMonth;
          });
          break;
        case "4day":
          const endOf4Days = new Date(this.currentDate);
          endOf4Days.setDate(endOf4Days.getDate() + 3); // Get 4 days from the current date

          filtered = this.bookings.filter((booking) => {
            const bookingDate = new Date(booking.start_datetime);
            return bookingDate >= this.currentDate && bookingDate <= endOf4Days;
          });
          break;
        default:
          filtered = this.bookings;
      }
      return filtered;
    },
    isCalendarView() {
      return this.viewType === 1;
    },
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Service", value: "service.service_name" },
        { text: "Customer", value: "customer.name" },
        { text: "Email", value: "customer.email" },
        { text: "Start Datetime", value: "start_datetime" },
        { text: "End Datetime", value: "end_datetime" },
      ],
      events: [],
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      currentDate: new Date(),
      componentKey: 0,
      viewType: 1,
      appointmentModal: false,
      appointmentDetails: null,
      actionType: "view",
    };
  },
  mounted() {
    this.fetchBookings();
    if(this.actionType =="create") this.appointmentData = []

  },
  methods: {
    openAppointmentDetails(event) {
      if(!event.data) {
        event.data = event;
      }
      this.appointmentDetails = event;
      this.appointmentModal = true;
      this.actionType = "view";
      this.componentKey++;
    },
    closeAppointmentModal(isCreated) {
      this.$store.dispatch("fetchBookings").then(()=>{
        this.componentKey++;
      this.$forceUpdate();
      this.appointmentModal = false;
      })
    
    },
    openAddAppointment() {
      this.appointmentModal = true;
      this.actionType = "create";
      this.componentKey++;
    },
    isSameDay(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },
    prev() {
      if (this.isCalendarView) {
        this.$refs.calendarRef.prev();
      } else {
        switch (this.type) {
          case "day":
            this.adjustDate(-1); // Adjust the current date by subtracting 1 day
            break;
          case "week":
            this.adjustDate(-7); // Adjust the current date by subtracting 7 days (1 week)
            break;
          case "month":
            this.adjustMonth(-1); // Adjust the current month by subtracting 1 month
            break;
          case "4day":
            this.adjustDate(-4); // Adjust the current date by subtracting 4 days
            break;
          default:
            break;
        }
      }
    },
    next() {
      if (this.isCalendarView) {
        this.$refs.calendarRef.next();
      } else {
        switch (this.type) {
          case "day":
            this.adjustDate(1); // Adjust the current date by adding 1 day
            break;
          case "week":
            this.adjustDate(7); // Adjust the current date by adding 7 days (1 week)
            break;
          case "month":
            this.adjustMonth(1); // Adjust the current month by adding 1 month
            break;
          case "4day":
            this.adjustDate(4); // Adjust the current date by adding 4 days
            break;
          default:
            break;
        }
      }
    },
    adjustDate(days) {
      const currentDate = new Date(this.currentDate);
      currentDate.setDate(currentDate.getDate() + days);
      this.setDate(currentDate);
    },
    adjustMonth(months) {
      const currentDate = new Date(this.currentDate);
      currentDate.setMonth(currentDate.getMonth() + months);
      this.setDate(currentDate);
    },
    setDate(date) {
      this.currentDate = date;

    },
    setDateToday() {
      this.type = "day";
      this.currentDate = new Date();
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    fetchBookings() {
      this.$store.dispatch("fetchBookings").then(() => {
        this.componentKey++;
      });
    },
  },
};
</script>

<style scoped>
</style>

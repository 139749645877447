import axios from "../../plugins/axios";
// state
export const state = () => ({
  services: [],
});

// getters
export const getters = {
  services: (state) => state.services,
};

// actions
export const actions = {
  fetchServices({ commit }) {
    axios
      .get("/api/services")
      .then((response) => {
        commit("SET_SERVICES", response.data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  },
  searchServices({ commit }, search) {
    axios
      .get(`/api/services/search?query=${search}`)
      .then((response) => {
        commit("SET_SERVICES", response.data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  },
  createService({ commit }, serviceData) {
    return axios.post("/api/services", serviceData);
  },
  updateService({ commit }, serviceData) {
    return axios.put(`/api/services/${serviceData.id}`, serviceData);
  },
  deleteService({ commit }, serviceId) {
    return axios
      .delete(`/api/services/${serviceId}`);
     
  },
};

// mutations
export const mutations = {
  SET_SERVICES(state, services) {
    state.services = services;
  },
  ADD_SERVICE(state, service) {
    state.services.push(service);
  },
  UPDATE_SERVICE(state, updatedTask) {
    const index = state.services.findIndex(
      (service) => service.id === updatedTask.id
    );
    if (index !== -1) {
      state.services.splice(index, 1, updatedTask);
    }
  },
  DELETE_SERVICE(state, serviceId) {
    state.services = state.services.filter(
      (service) => service.id !== serviceId
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

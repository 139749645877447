<template>
  <div>
    <v-container v-if="!customerBooking">
      <h1 class="display-5 text-center my-5">Choose Your Desired Service</h1>

      <v-row class="mb-5">
        <v-col>
          Filter:
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="primary" dark>
                {{ selectedCategory != "All" ? selectedCategory.name : "All" }}
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="filterServices('All')">
                <v-list-item-title>All</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="(category, index) in services_categories"
                :key="index"
                @click="filterServices(category)"
              >
                <v-list-item-title>{{ category.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="(service, index) in filteredServices"
          :key="index"
          cols="12"
          md="6"
          lg="4"
        >
          <v-card class="service-card mb-5">
            <!-- <v-img
              src="https://fakeimg.pl/600x400"
              alt="Service Image"
              class="service-image"
            ></v-img> -->
            <v-card-title class="headline">{{
              service.service_name
            }}</v-card-title>
            <!-- <v-card-subtitle class="subtitle-1"
              >Category: {{ service.category }}</v-card-subtitle
            > -->
            <v-card-subtitle class="subtitle-1">
              Price: P{{ service.price }}
              <br />
              Duration: {{ service.duration }}
            </v-card-subtitle>

            <v-card-text>Description: {{ service.description }}</v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" block @click="bookService(service)"
                >Book Now</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
    
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      categories: [],
      selectedCategory: "All",
      customerBooking: null,
    };
  },
  computed: {
    ...mapGetters(["services_categories", "services"]),
    filteredServices() {
      if (!this.selectedCategory || this.selectedCategory === "All") {
        return this.services;
      } else {
        return this.services.filter(
          (service) => service.category_id === this.selectedCategory.id
        );
      }
    },
  },
  methods: {
    bookService(service) {
      // Add logic to handle booking the service
      console.log(`Booked service: ${service.name}`);
      this.customerBooking = {
        selectedService: service,
        startDateTime: null,
        endDateTime: null,
        customer: null,
      };
      localStorage.setItem(
        "customerBooking",
        JSON.stringify(this.customerBooking)
      );

      this.$emit("changeStep", {
        step: 1,
        customerBooking: this.customerBooking,
      });
    },
    filterServices(category) {
      this.selectedCategory = category;
    },
  },
  mounted() {
    this.$store.dispatch("fetchServicesCategory");
    this.$store.dispatch("fetchServices");
  },
};
</script>
  <style scoped>
.category-btn-active {
  background-color: #2196f3;
  color: white;
}

.service-card {
  border-radius: 10px;
  overflow: hidden;
  transition: box-shadow 0.3s;
}

.service-card:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.service-image {
  height: 200px;
  object-fit: cover;
}

.subtitle-1 {
  color: grey;
}
</style>
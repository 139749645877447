import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import auth from './modules/auth'
import servicesCategory from './modules/servicesCategory'
import services from './modules/services'
import customers from './modules/customers'
import booking from './modules/booking'
import businessSettings from './modules/businessSettings'

export default new Vuex.Store({
    modules: {
        auth,
        servicesCategory,
        services,
        customers,
        booking  ,
        businessSettings
    },
})
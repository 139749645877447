<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredBookings"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.id }}</td>
          <td>{{ item.service.service_name }}</td>
          <td>
            {{
              (item.customer.name =
                item.customer.firstName + " " + item.customer.lastName)
            }}
          </td>
          <td>{{ item.customer.email }}</td>
          <td>{{ item.start_datetime }}</td>
          <td>{{ item.end_datetime }}</td>
          <td>
            <v-btn elevation="0" color="green" dark small class="mr-4" @click="viewBooking(item)"> View </v-btn>
            <v-btn elevation="0" color="orange" dark small> Cancel Booking </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>


<script>
export default {
  props: ["filteredBookings"],
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Service", value: "service.service_name" },
        { text: "Customer", value: "customer.name" },
        { text: "Email", value: "customer.email" },
        { text: "Start Datetime", value: "start_datetime" },
        { text: "End Datetime", value: "end_datetime" },
        { text: "Action", value: "Action" },
      ],
      selectedDates: [],
      events: [],
      currentDate: new Date(),
      componentKey: 0,
    };
  },

  methods: {
    viewBooking(item){
      this.$emit('openAppointmentDetails', item);

    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    fetchBookings() {
      this.$store.dispatch("fetchBookings").then(() => {
        this.componentKey++;
      });
    },
  },
};
</script>

<style scoped>
</style>

import Vue from 'vue'
import mainApp from './mainApp.vue'
import vuetify from './plugins/vuetify'
import router from './router/index'
import axios from './plugins/axios';
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.prototype.$axios = axios;
Vue.use(VueSweetalert2);


Vue.prototype.moment = moment

Vue.config.productionTip = false
import store from './store/store';
new Vue({
    vuetify,
    router,
    store,
    render: h => h(mainApp)
}).$mount('#app')
<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt=""
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="@/assets/corado.jpg"
          width="100"
          height="50"
        />
        <h2>Corado Aesthetics Booking System</h2>
      </div>

      <v-spacer></v-spacer>
      <div v-if="false">
        <v-btn> Login </v-btn>
        <v-menu offset-y v-if="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon large v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item link tile>
              <v-list-item-title dense>
                <v-icon>mdi-account-cog</v-icon> Change Password
              </v-list-item-title>
            </v-list-item>
            <v-list-item link tile @click="logout()">
              <v-list-item-title dense>
                <v-icon>mdi-logout</v-icon> Log out
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-container class="mt-10">
      <div class="mx-auto" style="margin-top: 50px">
        <v-img
          alt=""
          class="shrink mt-1 hidden-sm-and-down mx-auto"
          contain
          min-width="100"
          src="@/assets/corado.jpg"
          width="250"
        />
      </div>
      <ServicesList v-if="step == 0" @changeStep="changeStep" />

      <BookingCalendar
        v-if="step == 1"
        @changeStep="changeStep"
        :customerBooking="customerBooking"
        @back="step--"
      ></BookingCalendar>

      <CustomerInfo
        v-if="step == 2"
        @changeStep="changeStep"
        :customerBooking="customerBooking"
        @back="step--"
      ></CustomerInfo>

      <BookingCompleted
        v-if="step == 3"
        :customerBooking="customerBooking"
        @back="step--"
      ></BookingCompleted>
    </v-container>
  </v-app>
</template>
  
<script>
import BookingCalendar from "@/components/OnlineBooking/BookingCalendar.vue";
import ServicesList from "@/components/OnlineBooking/ServicesList.vue";

import CustomerInfo from "@/components/OnlineBooking/CustomerInfo.vue";

import BookingCompleted from "@/components/OnlineBooking/BookingCompleted.vue";
export default {
  components: {
    BookingCalendar,
    ServicesList,
    CustomerInfo,
    BookingCompleted,
  },
  data() {
    return {
      categories: [],
      selectedCategory: "All",
      customerBooking: null,
      step: 0,
    };
  },
  methods: {
    changeStep(data) {
      this.step = data.step;
      this.customerBooking = data.customerBooking;
    },
  },
};
</script>
<style scoped>
.category-btn-active {
  background-color: #2196f3;
  color: white;
}

.service-card {
  border-radius: 10px;
  overflow: hidden;
  transition: box-shadow 0.3s;
}

.service-card:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.service-image {
  height: 200px;
  object-fit: cover;
}

.subtitle-1 {
  color: grey;
}
</style>
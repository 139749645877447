<template>
  <v-card elevation="0">
    <v-card-text id="appointment-content">
      <v-select
        v-model="selectedStatus"
        :items="statusOptions"
        label="Select Booking Status"
        outlined
        
        @change="submitStatus"
      ></v-select>
      <h4 class="text-h5 mb-5">Customer Information</h4>
      <h4 class="text-h5 mb-5">{{ customerName }}</h4>
      <div>
        <v-row>
          <v-col cols="4"> Name </v-col>
          <v-col>{{ customerName }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Gender</v-col>
          <v-col>{{ appointmentData.data.customer.gender }}</v-col>
        </v-row>

        <v-row>
          <v-col cols="4">Email</v-col>
          <v-col>{{ appointmentData.data.customer.email }}</v-col>
        </v-row>

        <v-row>
          <v-col cols="4">Phone</v-col>
          <v-col>{{ appointmentData.data.customer.phoneNumber }}</v-col>
        </v-row>
      </div>

      <v-divider class="my-5" style="border-width: 3px"></v-divider>

      <div>
        <v-row>
          <v-col cols="4"> Service </v-col>
          <v-col>{{ appointmentData.data.service.service_name }}</v-col>
        </v-row>

        <v-row>
          <v-col cols="4">Price</v-col>
          <v-col>P{{ appointmentData.data.service.price }}</v-col>
        </v-row>

        <v-row>
          <v-col cols="4">Booking Date (From)</v-col>
          <v-col>{{ appointmentData.data.start_datetime }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Booking Date (To)</v-col>
          <v-col>{{ appointmentData.data.end_datetime }}</v-col>
        </v-row>

        <v-row>
          <v-col cols="4">Reference ID</v-col>
          <v-col>{{ appointmentData.data.reference_id }}</v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="4">Booking Notes</v-col>
          <v-col>{customerbooking.notes}</v-col>
        </v-row> -->
      </div>
    </v-card-text>
    <v-divider class="my-5"></v-divider>
    <v-card-actions>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            type="submit"
            color="green"
            dark
            x-large
            class="px-10 mr-4"
            @click="checkoutConfirmation()"
            
          >
            {{
              appointmentData.data.payment.status === "completed"
                ? "View Invoice"
                : "Checkout"
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  props: ["appointmentData"],
  computed: {
    customerName() {
      let customer = this.appointmentData.data.customer;
      return customer.lastName + ", " + customer.firstName;
    },
  },
  data() {
    return {
      dialog: true,
      isLoading: false,
      selectedStatus: null, // Bind this to your component's data or store
      statusOptions: [
        { text: "New", value: "new" },
        { text: "Confirmed", value: "confirmed" },
        { text: "No-Show", value: "no-show" },
        { text: "Canceled", value: "Canceled" },

        { text: "Completed", value: "completed" },
      ],
    };
  },
  methods: {
    checkoutConfirmation() {
      if(this.appointmentData.data.status == 'no-show' || this.appointmentData.data.status == 'canceled') {
        alert('Unable to Checkout if booking status is No Show or Canceled. Please update the booking status if to proceed.');
        return;
      }
      this.$emit('checkoutConfirmation')
    },
    submitStatus() {
      let data = {
        bookingId: this.appointmentData.data.id,
        status: this.selectedStatus,
      };
      this.$store.dispatch("updateBookingStatus", data).then((res) => {
        this.isLoading = false;
        this.$swal.fire("Booking Successfully updated", "", "success");
        this.$emit("closeDialog");

      });
    },
    cancelBooking() {
      let data = {
        bookingId: this.appointmentData.data.id,
        status: "canceled",
      };
      this.isLoading = true;

      this.$swal
        .fire({
          title: "Are you sure want to cancel the booking?",
          text: "This booking will be deleted on your calendar and You won't be able to revert this!",
          showDenyButton: true,
          confirmButtonText: "Yes, cancel it!",
          denyButtonText: `No`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$store.dispatch("updateBookingStatus", data).then((res) => {
              this.isLoading = false;
              this.$swal.fire("Booking Successfully canceled", "", "success");
              this.$emit("closeDialog");
              location.reload();
            });
            this.$emit("closeDialog");
          } else if (result.isDenied) {
            this.$swal.fire("Changes are not saved", "", "info");
          }
        });
    },
  },
  mounted() {
    this.selectedStatus = this.appointmentData.data.status;
  },
};
</script>
        
      

<style>
#appointment-content > h4,
#appointment-content > div > div > div:nth-child(1) {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

#appointment-content > div > div > div:nth-child(2) {
  font-size: 24px;
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import LoginGuard from "./guards/loginGuard.js";
import HomeGuard from "./guards/homeGuard.js";

import Login from "@/Pages/LoginPage.vue";

import DashboardPage from "../Pages/DashboardPage";
import App from "../App";
import CalendarPage from "../Pages/CalendarPage";
import OnlineBookingPage from "../Pages/OnlineBookingPage";
import BookingSettingsPage from "../Pages/BookingSettingsPage.vue";
import BookingCompleted from "@/components/OnlineBooking/BookingCompleted.vue";

import CustomerPage from "../Pages/CustomerPage";
import ReportPage from "../Pages/ReportPage";
import ServicesPage from "../Pages/ServicesPage";
import TeamPage from "../Pages/TeamPage";
import AppointmentPage from "../Pages/AppointmentPage";
const routes = [
  {
    path: "/",
    name: "App",
    component: App,
    beforeEnter: HomeGuard,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: DashboardPage,
      },
      {
        path: "/calendar",
        name: "Calendar",
        component: CalendarPage,
      },

      {
        path: "/customer",
        name: "Customer",
        component: CustomerPage,
      },
      {
        path: "/report",
        name: "Report",
        component: ReportPage,
      },
      {
        path: "/services",
        name: "Services",
        component: ServicesPage,
      },
      {
        path: "/team",
        name: "Team",
        component: TeamPage,
      },
      {
        path: "/appointments",
        name: "Apointments",
        component: AppointmentPage,
      },
      {
        path: "/booking-settings",
        name: "booking-settings",
        component: BookingSettingsPage,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: LoginGuard,
  },
  {
    path: "/onlinebooking",
    name: "Onlinebooking",
    component: OnlineBookingPage,
  },
  {
    path: "/onlinebooking/:id",
    name: "OnlinebookingRefPage",
    component: BookingCompleted,
  },
];
export default new VueRouter({
  mode: "history",
  routes,
});

import axios from "../../plugins/axios";

export const state = () => ({
  customers: [],
});

export const getters = {
  customers: (state) => state.customers,
};

export const actions = {
  fetchCustomers({ commit }) {
    axios
      .get("/api/customers")
      .then((response) => {
        commit("SET_CUSTOMERS", response.data);
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      });
  },
  searchCustomers({ commit }, search) {
    axios
      .get(`/api/customers/search?query=${search}`)
      .then((response) => {
        commit("SET_CUSTOMERS", response.data);
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      });
  },
  async createCustomer({ commit }, customerData) {
    return await axios.post("/api/customers", customerData);
  },
  async  updateCustomer({ commit }, customerData) {
    return await axios.put(`/api/customers/${customerData.id}`, customerData);
  },
  async deleteCustomer({ commit }, customerId) {
    return await axios.delete(`/api/customers/${customerId}`);
  },
};

export const mutations = {
  SET_CUSTOMERS(state, customers) {
    state.customers = customers;
  },
  ADD_CUSTOMER(state, customer) {
    state.customers.push(customer);
  },
  UPDATE_CUSTOMER(state, updatedCustomer) {
    const index = state.customers.findIndex(
      (customer) => customer.id === updatedCustomer.id
    );
    if (index !== -1) {
      state.customers.splice(index, 1, updatedCustomer);
    }
  },
  DELETE_CUSTOMER(state, customerId) {
    state.customers = state.customers.filter(
      (customer) => customer.id !== customerId
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-container fluid>
    <h2 class="mb-3">Dashboard</h2>
    <v-card class="pa-4 mb-5">
      <div class="d-flex justify-space-between mb-3 align-center">
        <h2 class="pr-5">{{ pageTitle }}</h2>
        <div style="margin-bottom: -6px">
          <DateRangePicker @select="select" @rangeSelected="rangeSelected" />
        </div>
      </div>
    </v-card>
    <v-row>
      <v-col v-for="(card, index) in cards" :key="index" lg="3">
        <v-card
          :color="card.color"
          min-height="100px"
          class="d-flex align-center white--text justify-space-between pa-6"
        >
          <v-card
            :color="card.color + '-lighten-1'"
            class="pa-2 d-flex justify-center"
            style="width: 50px"
          >
            <v-icon class="card-icons">{{ card.icon }}</v-icon>
          </v-card>
          <div class="text-right w-100">
            <div class="text-h5 font-weight-bold" v-if="card.formatCurrency">
              {{ formatData(card.data) }}
            </div>
            <div v-else class="text-h5 font-weight-bold">
              {{ card.value }}
            </div>
            <div>{{ card.title }}</div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-col cols="12" lg="12">
          <v-card>
            <v-card-title>
              Calendar Booking
            </v-card-title>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setDateToday()"
                >
                  Today
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>

            <v-card class="bordered">
              <v-card-title class="d-flex justify-space-between">{{
                this.getTitle
              }}</v-card-title>

              <appointment-calendar
                v-if="isCalendarView"
                ref="calendarRef"
                :bookings="bookings"
                :type="type"
                :key="componentKey"
              />
            </v-card>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { Line } from "vue-chartjs";
import axios from "axios";
import moment from "moment";
import AppointmentCalendar from "@/components/Appointment/AppointmentCalendar.vue";

import DateRangePicker from "@/components/@shared/DateRangePicker.vue";
import { mapGetters } from "vuex";

export default {
  components: { DateRangePicker, AppointmentCalendar },
  computed: {
    ...mapGetters(["bookings"]),
    getTitle() {
      switch (this.type) {
        case "day":
          return moment(this.currentDate).format("MMM Do, YYYY");
        case "week":
          const startOfWeek = moment(this.currentDate).startOf("week");
          const endOfWeek = moment(this.currentDate).endOf("week");
          return (
            startOfWeek.format("MMM Do") +
            " - " +
            endOfWeek.format("MMM Do, YYYY")
          );
        case "month":
          return moment(this.currentDate).format("MMMM YYYY");
        case "4day":
          const endOf4Days = moment(this.currentDate).add(3, "days");
          return (
            moment(this.currentDate).format("MMM Do") +
            " - " +
            endOf4Days.format("MMM Do, YYYY")
          );
        default:
          return "All bookings";
      }
    },
    filteredBookings() {
      let filtered = [];

      switch (this.type) {
        case "day":
          filtered = this.bookings.filter((booking) =>
            this.isSameDay(new Date(booking.start_datetime), this.currentDate)
          );
          break;
        case "week":
          const startOfWeek = new Date(this.currentDate);
          startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Get the start of the current week
          const endOfWeek = new Date(startOfWeek);
          endOfWeek.setDate(endOfWeek.getDate() + 6); // Get the end of the current week

          filtered = this.bookings.filter((booking) => {
            const bookingDate = new Date(booking.start_datetime);
            return bookingDate >= startOfWeek && bookingDate <= endOfWeek;
          });
          break;
        case "month":
          const startOfMonth = new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            1
          );
          const endOfMonth = new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth() + 1,
            0
          );

          filtered = this.bookings.filter((booking) => {
            const bookingDate = new Date(booking.start_datetime);
            return bookingDate >= startOfMonth && bookingDate <= endOfMonth;
          });
          break;
        case "4day":
          const endOf4Days = new Date(this.currentDate);
          endOf4Days.setDate(endOf4Days.getDate() + 3); // Get 4 days from the current date

          filtered = this.bookings.filter((booking) => {
            const bookingDate = new Date(booking.start_datetime);
            return bookingDate >= this.currentDate && bookingDate <= endOf4Days;
          });
          break;
        default:
          filtered = this.bookings;
      }
      return filtered;
    },
    isCalendarView() {
      return this.viewType === 1;
    },
  },
  data() {
    return {
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      pageTitle: "Today",
      menu: false,
      dateRange: "",
      dates: {
        start: null,
        end: null,
      },
      cards: [
        {
          title: "Total Bookings",
          icon: "mdi-calendar-check",
          value: 0,
          color: "#DB9A42",
        },
        {
          title: "Confirmed Revenue",
          icon: "mdi-cash-check",
          value: 0,
          color: "blue",
        },
        {
          title: "Projected Revenue",
          icon: "mdi-cash-plus",
          value: 0,
          color: "orange",
        },
        {
          title: "Total Revenue (Approx.)",
          icon: "mdi-cash-multiple",
          value: 0,
          color: "green",
        },
      ],
      headers: [
        { text: "ID", value: "id" },
        { text: "User ID", value: "user_id" },
        { text: "Service", value: "service" },
        { text: "Amount", value: "amount" },
        { text: "Paid At", value: "paid_at" },
        { text: "Status", value: "status" },
      ],
      recentBookings: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Revenue",
            backgroundColor: "#f87979",
            data: [],
          },
        ],
      },
      currentDate: new Date(),
      actionType: "view",
      viewType: 1,
      componentKey: 0 
    };
  },
  mounted() {
    this.fetchStats(this.currentDate, this.currentDate);
    this.fetchBookings();
  },
  methods: {
    updateDateRange(dates) {
      if (dates.start && dates.end) {
        this.dateRange = `${moment(dates.start).format(
          "YYYY-MM-DD"
        )} to ${moment(dates.end).format("YYYY-MM-DD")}`;
        this.fetchStats();
      }
    },
    async fetchStats(startDate, endDate) {
      // Replace with your API endpoint and include date range
      const params = { startDate, endDate };

      const response = await axios.get("/api/dashboard/stats", { params });
      const data = response.data;

      this.cards[0].value = data.totalBookings;
      this.cards[1].value = data.confirmedRevenue;
      this.cards[2].value = data.projectedRevenue;
      this.cards[3].value = data.totalRevenue;

      this.recentBookings = data.recentBookings;

      this.chartData.labels = data.revenueGraph.labels;
      this.chartData.datasets[0].data = data.revenueGraph.data;
    },
    rangeSelected(title) {
      this.pageTitle = title;
    },
    select(data) {
      this.fetchStats(data.startDate, data.endDate);
      // this.fetchTopSellingProducts(data.startDate, data.endDate);

      console.log({ data });
    },
    fetchBookings() {
      this.$store.dispatch("fetchBookings").then(() => {
        this.componentKey++;
      });
    },
    setDateToday() {
      this.type = "day";
      this.currentDate = new Date();
    },
    prev() {
      if (this.isCalendarView) {
        this.$refs.calendarRef.prev();
      } else {
        switch (this.type) {
          case "day":
            this.adjustDate(-1); // Adjust the current date by subtracting 1 day
            break;
          case "week":
            this.adjustDate(-7); // Adjust the current date by subtracting 7 days (1 week)
            break;
          case "month":
            this.adjustMonth(-1); // Adjust the current month by subtracting 1 month
            break;
          case "4day":
            this.adjustDate(-4); // Adjust the current date by subtracting 4 days
            break;
          default:
            break;
        }
      }
    },
    next() {
      if (this.isCalendarView) {
        this.$refs.calendarRef.next();
      } else {
        switch (this.type) {
          case "day":
            this.adjustDate(1); // Adjust the current date by adding 1 day
            break;
          case "week":
            this.adjustDate(7); // Adjust the current date by adding 7 days (1 week)
            break;
          case "month":
            this.adjustMonth(1); // Adjust the current month by adding 1 month
            break;
          case "4day":
            this.adjustDate(4); // Adjust the current date by adding 4 days
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.headline {
  font-size: 2rem;
  margin-left: 10px;
}
</style>

<template>
  <div class="">
    <v-card-text>
      <v-alert
        v-if="Object.keys(errorMessages.errors).length > 0"
        type="error"
        outlined
      >
        <ul>
          <li v-for="(errors, field) in errorMessages.errors" :key="field">
            <strong>{{ errors[0] }}</strong>
          </li>
        </ul>
      </v-alert>
    </v-card-text>
    <v-card>
      <v-card-title>Customer Information</v-card-title>
      <v-card-text>
        <v-select
          v-model="customerType"
          :items="customerTypes"
          label="Select Customer Type"
          outlined
        ></v-select>

        <v-form
          v-if="customerType === 'New Customer'"
          @submit.prevent="submit"
          ref="customerForm"
        >
          <v-row>
            <v-col class="pb-0">
              <v-text-field
                outlined
                label="First Name"
                hide-details
                class="mb-3"
                :error-messages="errorMessages.firstName"
                v-model="customerBooking.customer.firstName"
              ></v-text-field>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                label="Last Name"
                hide-details
                class="mb-3"
                v-model="customerBooking.customer.lastName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-select
                outlined
                label="Gender"
                hide-details
                class="mb-3"
                :items="genders"
                v-model="customerBooking.customer.gender"
              ></v-select>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                label="Phone Number"
                hide-details
                class="mb-3"
                v-model="customerBooking.customer.phoneNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Email Address"
                hide-details
                v-model="customerBooking.customer.email"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-form
          v-if="customerType === 'Existing Customer'"
          @submit.prevent="submit"
          ref="existingCustomerForm"
        >
          <v-combobox
            v-model="selectedExistingCustomer"
            :items="customerList"
            label="Select Existing Customer"
            item-text="name"
            item-value="id"
            outlined
            @change="populateCustomerData"
          ></v-combobox>
        </v-form>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12" lg="3">
        <v-card class="service-card my-5 pa-4">
          <v-card-title>Select Service</v-card-title>

          <v-card-text>
            <v-select
              outlined
              label="Service"
              :items="services"
              v-model="customerBooking.selectedService"
              item-text="service_name"
              return-object
              @change="generateTimeSlots"
            >
            </v-select>
            <v-simple-table>
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{{ customerBooking.selectedService?.service_name }}</td>
                </tr>

                <tr>
                  <td>Price:</td>
                  <td>{{ customerBooking.selectedService?.price }}</td>
                </tr>
                <tr>
                  <td>Duration:</td>
                  <td>{{ customerBooking.selectedService?.duration }}</td>
                </tr>
                <tr>
                  <td>Description:</td>
                  <td style="white-space: pre-line">
                    {{ customerBooking.selectedService?.description ?? "N/A" }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card v-if="customerBooking.selectedService" class="my-5">
          <v-card-text>
            <!-- Booking Form -->
            <v-form @submit.prevent="SubmitAndSaveBooking">
              <v-row>
                <v-col md="6" sm="12">
                  <v-card class="pb-2" id="datepicker_1">
                    <v-date-picker
                      v-model="bookingDate"
                      label="Booking Date"
                      required
                      full-width
                      color="primary"
                      :min="minDate"
                      @change="bookingDateChanged"
                    ></v-date-picker>
                  </v-card>
                </v-col>
                <v-col md="6" sm="12">
                  <v-card tile>
                    <v-card-title class="text-h5">Time Blocking </v-card-title>
                    <v-divider></v-divider>
                    <v-list class="py-0">
                      <v-list-item-group v-model="selectedTimeBlock">
                        <template>
                          <div v-for="(slot, i) in timeSlots" :key="i">
                            <v-list-item
                              @click="selectBookingTime(slot)"
                              :disabled="!isSlotAvailable(slot.availableSlots)"
                              color="blue"
                            >
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  slot.time
                                }}</v-list-item-title>
                                <v-list-item-subtitle>
                                  {{
                                    isSlotAvailable(slot.availableSlots)
                                      ? `Available Slots: ${slot.availableSlots}`
                                      : `Not Available - Fully booked`
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                          </div>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-card-actions>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            type="submit"
            color="red"
            outlined
            class="px-10 mr-4"
            @click="$emit('closeDialog')"
          >
            Cancel</v-btn
          >
          <v-btn
            type="submit"
            color="green"
            dark
            class="px-10 mr-4"
            :loading="loading"
            @click="SubmitAndSaveBooking()"
          >
            Save</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>
      
  <script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: ["appointmentData"],
  computed: {
    ...mapGetters(["services_categories", "services", "customers"]),
    customerList() {
      return this.customers.data.map((customer) => ({
        id: customer.id,
        name: `${customer.firstName} ${customer.lastName}`,
      }));
    },
  },
  watch: {
    customerType(newValue) {
      if (newValue === "Existing Customer") {
        this.$store.dispatch("fetchCustomers");
      }
    },
  },
  data() {
    return {
      selectedExistingCustomer: '',
      customerType: null,
      customerTypes: ["New Customer", "Existing Customer"],
      itemForm: {},
      genders: ["Male", "Female"],
      errorMessages: { errors: [] },
      dialog: true,
      selectedTimeBlock: "",
      customerName: "",
      email: "",
      bookingDate: this.format_date(new Date()),
      bookingStartDateTime: null,
      bookingEndDateTime: null,
      serviceType: "",
      serviceTypes: ["Haircut", "Massage", "Manicure", "Pedicure"],
      timeSlots: [],
      totalSlots: 5,
      minDate: this.format_date(new Date()),
      customerBooking: {
        selectedService: null,
        startDateTime: null,
        endDateTime: null,
        customer: {
          id: null,
          firstName: "",
          lastName: "",
          gender: "",
          phoneNumber: "",
          email: "",
        },
      },
      step: 1,
      loading: false,
    };
  },
  methods: {
    isBookingMatchSlot() {
      let existing =
        this.moment(this.customerBooking.startDateTime, "h:mma") +
        " - " +
        this.moment(this.customerBooking.endDateTime, "h:mma");

      let index = this.timeSlots.findIndex((item) => {
        console.log("slot: ", item.time);
        console.log("existing: ", existing);
        return item.time == existing;
      });
      this.selectedTimeBlock = index;
      console.log(this.selectedTimeBlock);
    },
    test() {
      return (
        this.moment(this.customerBooking.startDateTime, "hh:mma") +
        " - " +
        this.moment(this.customerBooking.endDateTime, "hh:mma")
      );
    },
    isSlotAvailable(availableSlots) {
      return availableSlots > 0;
    },
    bookingDateChanged() {
      this.generateTimeSlots();
    },
    moment(value, format) {
      return moment(String(value)).format(format);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    back() {
      if (this.step == 2) this.step--;
    },
    async SubmitAndSaveBooking() {
      this.loading = true;
      try {
        await this.$store
          .dispatch("createBooking", this.customerBooking)
          .then((res) => {
            this.loading = false;

            this.$swal.fire("Booking Successfully Created", "", "success");
            location.reload();
            this.$emit("closeDialog", true);

          })
          .catch((err) => {
            this.loading = false;

            this.errorMessages = err.response.data;
          });
      } catch (error) {
        this.loading = false;
      }
      return;
    },
    async generateTimeSlots() {
      try {
        const response = await this.$axios.get(
          "/api/generate-time-slots/" + this.bookingDate
        );
        this.timeSlots = response.data.time_slots;
        this.isBookingMatchSlot();
      } catch (error) {
        console.error("Error fetching time slots:", error);
      }
    },
    selectBookingTime(slot) {
      if (!this.bookingDate) {
        console.error("Error: bookingDate is not set.");
        return;
      }

      this.customerBooking.startDateTime = this.formatDateTime(
        new Date(this.bookingDate),
        slot.time.trim(),
        0
      );
      this.customerBooking.endDateTime = this.formatDateTime(
        new Date(this.bookingDate),
        slot.time.trim(),
        1
      );

      console.log("Selected Booking Date:", this.customerBooking.startDateTime);
      console.log("Selected Booking Date:", this.customerBooking.endDateTime);
    },
    formatDateTime(date, time, index) {
      time = time.split("-");
      const newTime = time[index];
      const [hour, minute] = newTime.split(":"); // Extract hour and AM/PM indicator
      let hourValue = parseInt(hour);

      // Adjust hour for PM times
      if (newTime.toLowerCase().includes("pm") && hourValue < 12) {
        hourValue += 12;
      }

      return moment(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          hourValue,
          parseInt(minute)
        )
      ).format("YYYY-MM-DD HH:mm");
    },
    populateCustomerData(customer) {
      console.log(customer);
      const selectedCustomer = this.customers.data.find(
        (item) => item.id === customer.id
      );
      if (selectedCustomer) {
        this.customerBooking.customer.id = selectedCustomer.id;

        this.customerBooking.customer.firstName = selectedCustomer.firstName;
        this.customerBooking.customer.lastName = selectedCustomer.lastName;
        this.customerBooking.customer.gender = selectedCustomer.gender;
        this.customerBooking.customer.phoneNumber =
          selectedCustomer.phoneNumber;
        this.customerBooking.customer.email = selectedCustomer.email;
      }
    },
  },
  mounted() {
    this.$store.dispatch("fetchCustomers");

    this.generateTimeSlots();
    this.$store.dispatch("fetchServices");
    if (this.appointmentData) {
      this.customerBooking.selectedService = this.appointmentData.data.service;
      this.customerBooking.startDateTime = this.appointmentData.start;
      this.customerBooking.endDateTime = this.appointmentData.end;
      this.customerBooking.customer = this.appointmentData.data.customer;
      this.bookingDate = this.format_date(this.appointmentData.start);
    }
  },
};
</script>
      
    
    <style>
.v-date-picker-table .v-btn {
  z-index: auto;
  margin: 0;
  font-size: 17px;
}

#datepicker_1
  > div
  > div.v-picker__body.theme--light
  > div
  > div.v-date-picker-header.theme--light {
  height: 120px;
}
#datepicker_1
  > div
  > div.v-picker__body.theme--light
  > div
  > div.v-date-picker-header.theme--light
  > div
  > div
  > button {
  font-size: 2rem;
}
</style>